<template>
  <b-sidebar id="add-new-Banner-sidebar" :visible="isAddNewBannerSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-banner-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Add") }} {{ $t("New") }} Banner</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Full Name -->
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input id="name" v-model="stateData.name" autofocus :state="getValidationState(validationContext)"
                trim placeholder="text" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type -->
          <validation-provider #default="validationContext" name="Banner type" rules="required">
            <b-form-group label="Banner type" label-for="file">
              <v-select id="file" v-model="stateData.type" :state="getValidationState(validationContext)"
                :options="optionsFile" :reduce="(val) => val.value" :clearable="false" label="text" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Full Name -->

          <validation-provider #default="validationContext" name="field">
            <b-form-group label="File" label-for="field">
              <b-form-file v-if="stateData.type === 0" id="file-small" accept="image/png, image/jpeg"
                :state="getValidationState(validationContext)" @change="onFileChange($event)" />
              <!-- <b-form-textarea
                v-else
                id="field"
                v-model="stateData.dir_img"
                :state="getValidationState(validationContext)"
                trim
                placeholder="text"
              >
              </b-form-textarea> -->
              <div v-else>
                <div id="toolbar">
                  <button type="button" class="ql-bold"></button>
                  <button type="button" class="ql-italic"></button>
                  <button type="button" class="ql-underline"></button>
                  <button type="button" class="ql-strike"></button>
                  <button type="button" class="ql-blockquote"></button>
                  <button type="button" class="ql-code-block"></button>
                  <button type="button" class="ql-list" value="ordered"></button>
                  <button type="button" class="ql-list" value="bullet"></button>
                  <button type="button" class="ql-script" value="sub"></button>
                  <button type="button" class="ql-script" value="super"></button>
                  <button type="button" class="ql-indent" value="-1"></button>
                  <button type="button" class="ql-indent" value="+1"></button>
                  <button type="button" class="ql-direction"></button>
                  <select class="ql-size">
                    <option value="small"></option>
                    <option value="false"></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                  </select>
                  <select class="ql-header">
                    <option value="1"></option>
                    <option value="2"></option>
                    <option value="3"></option>
                    <option value="4"></option>
                    <option value="5"></option>
                    <option value="6"></option>
                    <option value="false"></option>
                  </select>
                  <select class="ql-font"></select>
                  <select class="ql-color"></select>
                  <select class="ql-background"></select>
                  <select class="ql-align"></select>
                  <button type="button" class="ql-clean"></button>
                  <button type="button" class="ql-link"></button>
                </div>
                <quill-editor ref="myQuillEditor" :options="editorOption" v-model="stateData.text"
                  :state="getValidationState(validationContext)" :class="{
                    'is-invalid':
                      validationContext.touched && !validationContext.valid,
                  }">
                </quill-editor>
              </div>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-img v-if="photoSelected" :src="photoSelected" alt="example" class="img-fluid" />
          <!-- start Date-->
          <validation-provider #default="validationContext" name="Date From" rules="">
            <b-form-group label="Date From" label-for="date_from">
              <flat-pickr id="date_from" v-model="stateData.start_date" :config="fConfig" class="form-control"
                placeholder="MM-DD-YYYY" :state="getValidationState(validationContext)" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end Date -->
          <validation-provider #default="validationContext" name="Date to" rules="">
            <b-form-group label="Date to" label-for="date_to">
              <flat-pickr id="date_to" v-model="stateData.end_date" :config="uConfig" class="form-control"
                placeholder="MM-DD-YYYY" :state="getValidationState(validationContext)" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Roles" rules="required">
            <b-form-group label="Roles" label-for="name">
              <v-select v-model="stateData.roles" :options="rolesOptions" :reduce="(val) => val.id" multiple
                label="name" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <validation-provider #default="validationContext" name="Status" rules="required">
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <v-select id="status" v-model="stateData.status" :state="getValidationState(validationContext)"
                :options="optionsStatus" :reduce="(val) => val.value" :clearable="false" label="text" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
              :disabled="loading">
              <span v-if="!loading">{{ $t("Add") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-danger" @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
  BImg,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import SpinnerLoading from "@/components/SpinnerLoading";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref } from "@vue/composition-api";

import axiosT from "@/services/admin/template";
import axiosEC from "@/services/external-settings/images";
import axiosR from "@/services/admin/roles";
import { fromConfig, untilConfig } from "@/mixins/configFormatCal";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    BImg,
    SpinnerLoading,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewBannerSidebarActive",
    event: "update:is-add-new-banner-sidebar-active",
  },
  props: {
    isAddNewBannerSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      fConfig: fromConfig({ dateFormat: "m-d-Y", }),
      uConfig: untilConfig({ dateFormat: "m-d-Y", }),
      editorOption: {
        modules: {
          toolbar: {
            container: "#toolbar",
          },
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
        theme: "snow",
      },
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];
    const optionsFile = [
      { text: "Image", value: 0 },
      { text: "Text", value: 1 },
    ];

    const stateDataTem = {
      name: "",
      type: 0,
      status: true,
      dir_img: "",
      text: null,
      start_date: null,
      end_date: null,
    };

    const fileResource = ref(null);
    const photoSelected = ref("");
    const changePhoto = ref(false);

    const onFileChange = (event) => {
      //returns an array of files even though multiple not used
      fileResource.value = event.target.files[0];
      photoSelected.value = URL.createObjectURL(fileResource.value);
      changePhoto.value = true;
    };

    const uploadPhoto = async (file) => {
      const formData = new FormData();
      formData.append("type", "BANNER");
      formData.append("file", file);
      const { dir_img } = await axiosEC.createImage(formData);
      return dir_img;
    };

    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));
    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };

    onMounted(() => {
      getRoles();
    });
    const rolesOptions = ref([]);

    const getRoles = () => {
      axiosR.rolesList().then(({ data }) => {
        rolesOptions.value = data;
      });
    };

    const loading = ref(false);
    const onSubmit = async () => {
      loading.value = true;
      if (changePhoto.value) {
        const photoUrl = await uploadPhoto(fileResource.value);
        stateData.value.dir_img = photoUrl;
        changePhoto.value = false;
      }
      axiosT
        .bannerCreate(stateData.value)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("createBanner", true);
        })
        .catch(() => {
          emit("createBanner", false);
          loading.value = false;
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);
    return {
      loading,
      stateData,
      optionsFile,
      optionsStatus,
      rolesOptions,
      onSubmit,
      photoSelected,
      refFormObserver,
      getValidationState,
      onFileChange,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
