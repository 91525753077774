<template>
  <b-row>
    <b-col md="8">
      <ConfigurationBanner
        v-if="$can('index', 'module_system_settings.settings.web_settings.template')"
        title="Banner"
        :labels="['home', 'config', 'banner']"
      />
    </b-col>
    <b-col md="4">
      <ConfigurationColors
        v-if="configId != null && $can('index', 'module_system_settings.settings.web_settings.template')"
        title="Colors"
        :colorConfig="colorConfig"
        :configId="configId"
        :labels="['home', 'config', 'colors']"
        @editConfig="editConfig"
      />
      <ConfigurationLogo
        v-if="logoImage && $can('index', 'module_system_settings.settings.web_settings.template')"
        title="Logo"
        :logoImageDefault="logoImage"
        :configId="LoginId"
        :labels="['Logo', 'config', 'page']"
        @editConfig="editConfig"
      />
      <ConfigurationLogin
        v-if="loginImage && $can('index', 'module_system_settings.settings.web_settings.template')"
        title="Banner login"
        :loginImageDefault="loginImage"
        :configId="configId"
        :labels="['login', 'config', 'banner']"
        @editConfig="editConfig"
      />
    </b-col>
    <ToastNotification ref="toast" />
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import ConfigurationBanner from "@/views/admin/configuration/ConfigurationBanner.vue";
import ConfigurationColors from "@/views/admin/configuration/ConfigurationColors.vue";
import ConfigurationLogin from "@/views/admin/configuration/ConfigurationLogin.vue";
import ConfigurationLogo from "@/views/admin/configuration/ConfigurationLogo.vue";

import ToastNotification from "@/components/ToastNotification";

import axiosC from "@/services/admin/config";

export default {
  components: {
    BRow,
    BCol,
    ConfigurationBanner,
    ConfigurationColors,
    ConfigurationLogin,
    ConfigurationLogo,
    ToastNotification
  },
  created() {
    axiosC.configActive().then(({ login_dir_img, color, id }) => {
      (this.loginImage = login_dir_img), id;
      this.colorConfig = color;
      this.configId = id;
    });
    axiosC.confiLogoActive().then(({ dir_img, id }) => {
      this.logoImage = dir_img;
      this.LoginId = id;
    });
  },
  data() {
    return {
      loginImage: "",
      colorConfig: null,
      configId: null,
      logoImage: null,
      LoginId: null,
    };
  },
  methods: {
    editConfig(value) {
      if (value) {
        this.$refs.toast.success("Configuration updated successfully");
      } else {
        this.$refs.toast.danger("Error updating Configuration");
      }
    },
  },
};
</script>
