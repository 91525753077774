<template>
  <b-card class="card-app-design text-center">
    <b-card-title class="mt-1 mb-75">
      {{ title }}
    </b-card-title>
    <b-card-text class="font-small-2 mb-2">
      you can activate, deactivate, delete or add an image to view at login
    </b-card-text>

    <!-- design group -->
    <div class="design-group">
      <h6 class="section-label">labels</h6>
      <b-badge
        variant="light-warning"
        class="mr-1"
        v-for="(label, i) in labels"
        :key="i"
      >
        {{ label }}
      </b-badge>
    </div>
    <b-form-file
      id="extension"
      size="sm"
      class="my-1"
      accept=".jpg, .png, .gif"
      @change="onFileChange($event)"
    ></b-form-file>
    <b-img
      :src="photoSelected"
      @click="viewImage"
      class="cursor-pointer"
      fluid
      alt="login image"
    />

    <!-- button -->
    <b-button
      class="my-2"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      v-if="$can('ajust_app_sa', 'module_system_settings.settings.web_settings.templat')"
      @click="saveChanges"
    >
      Save Changes
    </b-button>
    <b-modal
      v-model="viewImageBoolean"
      hide-footer
      hide-header
      centered
      size="lg"
    >
      <b-img :src="previewImage" fluid-grow></b-img>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BButton,
  BImg,
  BFormFile,
  BModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import axiosCT from "@/services/admin/config";
import axiosEC from "@/services/external-settings/images";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BCardTitle,
    BCardText,
    BImg,
    BFormFile,
    BModal,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    labels: {
      type: Array,
      required: false,
      default: () => [],
    },
    loginImageDefault: {
      type: [String, null],
      required: true,
    },
    configId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      previewImage: null,
      viewImageBoolean: false,
      photoSelected: this.loginImageDefault,
      fileResource: null,
      changePhoto: false,
    };
  },
  methods: {
    onFileChange(event) {
      //returns an array of files even though multiple not used
      this.fileResource = event.target.files[0];
      this.photoSelected = URL.createObjectURL(this.fileResource);
      this.changePhoto = true;
    },
    async uploadPhoto(file) {
      const formData = new FormData();
      formData.append("type", "LOGIN");
      formData.append("file", file);
      const { dir_img } = await axiosEC.createImage(formData);
      return dir_img;
    },
    viewImage(image) {
      this.viewImageBoolean = true;
      this.previewImage = image.target.src;
    },
    async saveChanges() {
      if(this.changePhoto){
        const photoUrl = await this.uploadPhoto(this.fileResource);
        this.photoSelected = photoUrl;
        this.changePhoto = false
      }
      const datos = {
        login_dir_img: this.photoSelected,
      };
      axiosCT
        .configLoginUpdate(this.configId, datos)
        .then(({ status }) => {
          if(status == "ok"){
            this.$emit("editConfig", true);
          }else{
            this.$emit("editConfig", false);
          }
        })
    },
  },
};
</script>
