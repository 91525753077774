import { userAxios } from "../index";

const bannerList = async () => {
    try {
        return await userAxios.get('banners').then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const bannerUpdate = async (id, data) => {
    try {
        return await userAxios.put(`banners/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const bannerCreate = async (data) => {
    try {
        return await userAxios.post('banners', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const bannerActive = async () => {
    try {
        return await userAxios.get(`banner/active`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const bannerDelete = async (id) => {
    try {
        return await userAxios.delete(`banners/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    bannerList,
    bannerUpdate,
    bannerActive,
    bannerCreate,
    bannerDelete
}