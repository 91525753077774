<template>
  <b-card class="card-app-design text-center">
    <b-card-title class="mt-1 mb-75">
      {{ $t(title) }}
    </b-card-title>
    <b-card-text class="font-small-2 mb-2">
      you can change the template colors for all users!
    </b-card-text>

    <!-- design group -->
    <div class="design-group">
      <h6 class="section-label">labels</h6>
      <b-badge
        variant="light-warning"
        class="mr-1"
        v-for="(label, i) in labels"
        :key="i"
      >
        {{ label }}
      </b-badge>
    </div>
    <b-form-group label="Navbar Color">
      <div
        v-for="(color, index) in navbarColors"
        :key="color"
        class="p-1 d-inline-block rounded mr-1 cursor-pointer"
        :class="[
          `bg-${color}`,
          { 'border border-light': !index },
          { 'mark-active': navbarBackgroundColor === color },
        ]"
        @click="navbarBackgroundColor = color"
      />
      <b-button
        class="my-2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        v-if="$can('ajust_app_sa', 'module_system_settings.settings.web_settings.templat')"
        @click="changeColor(navbarBackgroundColor)"
      >
        Save Changes
      </b-button>
    </b-form-group>
    <!-- button -->
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BButton,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import useAppCustomizer from "@core/layouts/components/app-customizer/useAppCustomizer";
import axiosCT from "@/services/admin/config";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BCardTitle,
    BCardText,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    configId: {
      type: Number,
      required: true,
    },
    labels: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const config = props.configId;
    const { navbarColors, navbarBackgroundColor } = useAppCustomizer();
    const changeColor = (color) => {
      const datos = {
        color: color === "" ? "light" : color,
      };
      axiosCT
        .configLoginUpdate(config, datos)
        .then(() => {
          emit("editConfig", true);
        })
        .catch(() => {
          emit("editConfig", false);
        });
    };
    return {
      navbarColors,
      navbarBackgroundColor,
      changeColor,
    };
  },
  methods: {},
};
</script>