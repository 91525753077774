<template>
  <div>
    <BannerEdit
      v-if="isEditBannerSidebarActive"
      :is-edit-banner-sidebar-active.sync="isEditBannerSidebarActive"
      :itemEdit="itemEdit"
      @editVisitSuccess="editVisitSuccess"
    />
    <BannerAdd
      :isAddNewBannerSidebarActive.sync="isAddNewBannerSidebarActive"
      @createBanner="createBanner"
    />
    <b-card class="card-app-design text-center">
      <b-card-title class="mt-1 mb-75">
        {{ title }}
      </b-card-title>
      <b-card-text class="font-small-2 mb-2">
        you can activate, deactivate, delete or add an image to view at startup
        as a pop-up window
      </b-card-text>

      <!-- design group -->
      <div class="design-group">
        <h6 class="section-label">labels</h6>
        <b-badge
          variant="light-warning"
          class="mr-1"
          v-for="(label, i) in labels"
          :key="i"
        >
          {{ label }}
        </b-badge>
      </div>
      <b-row>
        <b-col md="12" class="text-right">
          <b-button
            variant="gradient-primary"
            class="btn-icon rounded-circle m-1"
            v-if="$can('store', 'module_help_center.learning_center.banners')"
            @click="isAddNewBannerSidebarActive = true"
          >
            <feather-icon icon="ImageIcon" />
          </b-button>
        </b-col>
      </b-row>

      <!-- Table -->
      <b-table
        striped
        responsive
        bordered
        hover
        :fields="fields"
        :items="items"
        class="table"
      >
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>
        <template #cell(roles)="data">
          <b-badge
            variant="primary"
            v-for="(rol, i) in data.item.roles"
            :key="i"
            >{{ rol.name }}</b-badge
          >
        </template>
        <template #cell(image)="data">
          <!-- Image -->
          <b-img
            v-if="data.item.type === 0"
            :src="data.item.dir_img"
            :alt="data.item.name"
            rounded
            fluid-grow
            class="cursor-pointer"
            @click="viewImage(data.item.dir_img)"
          />
          <div
            v-else
            ref="content"
            class="ql-editor"
            v-html="data.item.text"
          ></div>
        </template>
        <template #cell(action)="data">
          <div>
            <feather-icon
              icon="EditIcon"
              size="15"
              class="cursor-pointer"
              v-if="$can('update', 'module_help_center.learning_center.banners')"
              @click="EditBanner(data.item)"
            />
          </div>
          <div>
            <feather-icon
              :icon="
                data.item.status == true ? 'ToggleLeftIcon' : 'ToggleRightIcon'
              "
              :fill="data.item.status == true ? 'green' : 'red'"
              size="15"
              class="cursor-pointer"
              v-if="$can('update', 'module_help_center.learning_center.banners')"
              @click="ActiveBanner(data.item)"
            />
          </div>
          <div>
            <feather-icon
              icon="TrashIcon"
              size="15"
              class="cursor-pointer"
              v-if="$can('destroy', 'module_help_center.learning_center.banners')"
              @click="deleteBanner(data.item)"
            />
          </div>
        </template>
      </b-table>
      <b-modal
        v-model="viewImageBoolean"
        hide-footer
        hide-header
        centered
        size="lg"
      >
        <b-img :src="previewImage" fluid-grow></b-img>
      </b-modal>
      <ToastNotification ref="toast" />
    </b-card>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardTitle,
  BCardText,
  BButton,
  BImg,
  BTable,
  BIcon,
  BFormCheckbox,
  BModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastNotification from "@/components/ToastNotification";

import BannerAdd from "./BannerAdd.vue";
import BannerEdit from "./BannerEdit.vue";

import axiosT from "@/services/admin/template";
import { ref } from "vue-demi";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    BCardTitle,
    BCardText,
    BImg,
    BTable,
    BIcon,
    BFormCheckbox,
    BModal,
    ToastNotification,
    BannerAdd,
    BannerEdit,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    labels: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        "name",
        "roles",
        {
          label: "Image",
          key: "image",
          editable: true,
          tbClass: "bTableThStyle",
        },
        ,
        "action",
      ],
      items: [],
      previewImage: null,
      viewImageBoolean: false,
      isAddNewBannerSidebarActive: false,
      isEditBannerSidebarActive: false,
      itemEdit: null,
    };
  },
  mounted() {
    this.getBanners();
  },
  methods: {
    getBanners() {
      axiosT.bannerList().then((res) => {
        this.items = res.data;
      });
    },
    editVisitSuccess(value) {
      if (value) {
        this.isEditBannerSidebarActive = false;
        this.getBanners();
      } else {
      }
    },
    viewImage(image) {
      this.viewImageBoolean = true;
      this.previewImage = image;
    },
    EditBanner(item) {
      this.isEditBannerSidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    ActiveBanner(item) {
      this.previewImage = item.dir_img;
      axiosT
        .bannerUpdate(item.id, { ...item, status: !item.status })
        .then((res) => {
          this.$refs.toast.success("Banner updated");
          this.getBanners();
        })
        .catch((err) => {
          this.$refs.toast.success("Banner not updated");
        });
    },
    deleteBanner(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosT
            .bannerDelete(item.id)
            .then((res) => {
              this.$refs.toast.success("Banner deleted");
              this.getBanners();
            })
            .catch((err) => {
              this.$refs.toast.success("Banner not deleted");
            });
        }
      });
    },
    createBanner(value) {
      if (value) {
        this.$refs.toast.success("Banner created");
        this.isAddNewBannerSidebarActive = false;
        this.getBanners();
      } else {
        this.$refs.toast.danger("Banner not added");
        this.isAddNewBannerSidebarActive = false;
      }
    },
  },
};
</script>
